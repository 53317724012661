<template>
  <div>
    <v-row
        style="background-color: #ECEFF1;">
      <v-col>
        <v-container>
        <v-row>
          <v-spacer></v-spacer>
          <v-col>
            <v-img height="150" contain src="@/assets/agendease-logo-name-below-transparent.png"></v-img>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-col>
            <h2>Welcome to Agendease!</h2>
            <p>You are one step closer to unlocking all the benefits of your <b color=primary>Agendease</b> account. You can enjoy the benefits of <b>Agendease</b> in several ways. Most obvious, you can sign up your organization to make the process of building its meeting packets much easier than the way it is being done right now. How? Let us show you. </p>
            <v-btn
            color=info
            class="elevation-10"
            large>Put my organization on Agendease</v-btn>
          </v-col>
          <v-col>
            <v-img
              max-width="250"
              height="250"
              contain
              src="@/assets/welcome-image.png"></v-img>
            </v-col>
          <v-spacer></v-spacer>
        </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-row
      white>
      <v-col>
        <v-container
        class="pt-5">
        <v-row justify="space-around">
          <v-spacer></v-spacer>
          <v-col
            class="pa-5"
            style="border: solid 1px lightgrey;"
            cols="12"
            md="6"
            lg="4"
            xl="3">
            <p
            style="min-height:100px;">Are you part of an organization that is already using <b>Agendease</b> to make its meetings easier? Getting added to the organization's acount is easy. All you need to do is send a request.</p>
            <v-btn
              color=accentYellow
              large
              class="white--text"
              text
              block
              href="/join-organization"
              >
                <v-icon
                  size="18"
                  left>mdi-plus</v-icon>
                Request to be added to the organization's account</v-btn>
          </v-col>
          <v-col
            cols="12"
            lg="1"></v-col>
          <v-col
            class="pa-5"
            style="border: solid 1px lightgrey;"
            cols="12"
            md="6"
            lg="4"
            xl="3">
            <p
            style="min-height:100px;">The organizations that use <b>Agendease</b> to build complete meeting packets send out notifications when their meeting documents are available to view. You can receive notification of the meetings you are interested in by just signing up.</p>
            <v-btn
              color=secondary
              large
              text
              block
              href="/notification-signup"
              >
                <v-icon
                  size="18"
                  left>mdi-map-search-outline</v-icon>
                Look for organizations you are interested in</v-btn>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from 'vue'
import Portal from '@/components/Portal'

export default Vue.extend({
  name: 'Welcome',
  components: {
  },
  data: () => ({
  })
})
</script>
